.App {
  text-align: center;
  background-color: #F8F8F8; /* Lighter background */
  color: #333333; /* Darker text for better readability */
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
}

.App-header {
  background-color: #F8F8F8; /* Lighter header background */
  padding: 20px;
  color: #333333; /* Darker text color */
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

/* Keyframes remain the same */
@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

button {
  background-color: #02afff; /* Changed to a more neutral button color */
  color: #FFFFFF; /* White text on buttons */
  border: none;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(76, 175, 80, 0.24), 0 2px 2px 0 rgba(76, 175, 80, 0.19);
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: #388E3C; /* Darker shade for hover effect */
  box-shadow: 0 2px 3px 0 rgba(76, 175, 80, 0.34), 0 3px 4px 0 rgba(76, 175, 80, 0.29);
}

input[type="text"], input[type="password"], input[type="number"] {
  font-size: 16px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #CCCCCC; /* Added border for inputs */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(200, 200, 200, 0.2);
  transition: all 0.2s ease-in-out;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="number"]:focus {
  outline: none;
  border-color: #4CAF50; /* Focus border color */
  box-shadow: 0 3px 6px rgba(76, 175, 80, 0.3);
}

a {
  color: #4CAF50; /* Links in green for contrast */
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: #388E3C; /* Darker shade on hover for links */
}
