.home-container {
  padding-top: 60px; /* Space for fixed top buttons */
  padding-bottom: 60px; /* Space for fixed bottom bar */
  background-color: #FAFAFA; /* Bright background */
}

.top-buttons {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap onto the next line */
  justify-content: space-around;
  background-color: #E6E6E6; /* Lighter shade for top buttons */
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 100;
  color: #333; /* Darker text for better readability */
}

.debtmessage {
  background-color: #ffcccc; /* Light red background */
  color: #cc0000; /* Dark red text */
  padding: 20px;
  border: 2px solid #cc0000;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 20px;
}


.bottominputs {
  display: flex;
}

/* Items container */
.items-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust number of items based on the screen width */
  gap: 15px;
  padding: 4px;
  justify-content: center;
  background-color: #FAFAFA; /* Slightly off-white background for items container */
}

.item {
  border: 6px solid #ddd;
  border-radius: 30px;
  padding: 10px;
  text-align: center;
  justify-content: space-between; /* This will distribute space evenly */
  max-width: 200px;
  background-color: #FFFFFF; /* White background for items */
  color: #333; /* Darker text color for items */
}

.item.flowers {
  border-color: rgb(255, 101, 181); /* Specific shade of pink/red */
}

.item.vegetables {
  border-color: rgb(0, 128, 0); /* Green color for vegetables */
}

.item.others {
  border-color: rgb(47, 224, 255); /* Blue color for others */
}

input::placeholder {
  text-align: center;
  color: #666; /* Darker placeholder text */
}

.product-image {
  max-width: 100%;  /* ensures the image is not wider than its container */
  height: 10vh;     /* maintains the aspect ratio */
  display: block;   /* prevents inline behaviors */
  margin: 0 auto;   /* centers the image if it's not as wide as the container */
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.item-info h3 {
  margin: 5px 0;
  font-size: 1.1em;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2); /* Adjust text shadow for readability */
  color: #333; /* Darker text color */
}

.item-info p {
  margin: 5px 0;
  color: #555;
}

.item-quantity input {
  width: 30%;
  padding: 5px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333; /* Darker text color */
}

.item-quantity button {
  padding: 5px 2px;
  background-color: #28a745;
  color: white;
  border: 2px solid #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.home-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.item-total {
  background-color: rgba(255, 255, 255, 0.8); /* Light background with slight opacity */
  color: #333; /* Darker text color */
  padding: 0px;
  border-radius: 50px;
  font-size: 1.5rem; /* Adjust font size as needed */
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #E6E6E6; /* Lighter shade for bottom bar */
  padding: 10px 0;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  text-align: center;
  z-index: 100;
  color: #333; /* Darker text color for better readability */
  transition: transform 0.3s ease-in-out;

}

.bottom-bar button {
  padding: 10px 20px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bottom-bar input {
  
  padding: 5px;
  width: 50%;
  margin-right: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333; /* Darker text color */
}

.popup {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to be above everything else */
}

.popup-content {
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Higher than the semi-transparent background */
  border-width: 1px;
  border-color: #02afff;
  border-style: solid;

}

@media (max-width: 768px) {
  .items-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust for smaller screens */
  }
}

.filter-input {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 1000; /* Ensure it's above other elements */
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.small-edit-btn {
  font-size: 0.8rem; /* Smaller font size */
  padding: 2px 5px; /* Smaller padding */
  /* Additional styling as needed */
}
td {
  border: 1px solid #ddd; /* Border around table cells */
}

tr {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Inner shadow */
}

table {
  border-collapse: collapse; /* Remove space between cells */
  margin-bottom: 150px;
}
tbody {
  background-color: #FFFFFF; /* White background for table body */
  color: #333; /* Darker text color */

}


input[type="number"],
input[type="tel"] {
  font-size: 16px; /* Prevent zoom-in effect on mobile */
}
.small-btn {
  font-size: 0.9rem; /* Smaller font size */
  padding: 2px 5px; /* Smaller padding */
  /* Additional styling as needed */
}
@media (max-width: 480px) {
  .top-buttons {
    flex-direction: row;
    flex-wrap: wrap; /* Wrap buttons if they don't fit in one line */
    justify-content: center; /* Center buttons when they wrap */
  }

  .top-buttons button {
    flex-basis: calc(33% - 4px); /* Adjust button width to fit three per row */
    max-height: 30px;
    margin: 2px;
  }

  .table-container {
    grid-template-columns: repeat(3, 1fr); /* Three items per row on smaller screens */
  }



  .item {
    border: 6px solid #ddd;
    border-radius: 30px;
    padding: 10px;
    text-align: center;
    justify-content: space-between; /* This will distribute space evenly */
    max-width: 200px;
    background-color: #FFFFFF; /* White background for items */
    color: #333; /* Darker text color for items */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2); /* Inner shadow */
  }
  

  .item-info h3, .item-info p {
    font-size: 0.8rem; /* Reduced font size */
    /* Other styling adjustments for text */
  }

  
  .small-edit-btn {
    font-size: 0.4rem; /* Smaller font size */
    padding: 2px 5px; /* Smaller padding */
    /* Add any other styling as needed */
  }

  .item-quantity button {
    padding: 1px 0px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .h6 {
    font-size: 0.8rem;
  }

  .item-total {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }

}

  

  .rtl {
    direction: rtl;
    text-align: right;
  }